<template>
  <r-page-modal :prompt-on-leave="true" prompt-message="Are You sure?">
    <template #page-header>
      <h2 class="page-title">Add New Metode</h2>
      <r-third-level-menu />
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <b-button
            tag="router-link"
            class="is-command"
            icon-left="account-plus"
            to="/master-data/cara-pembayaran/metode/create"
          >
            New
          </b-button>
        </div>
        <div class="level-right">
          <div class="column is-12">
            <b-input type="search" rounded icon-right="magnify"></b-input>
          </div>
          <r-filter dropdown-position="bottom left">
            <div class="columns">
              <div class="column is-half">
                <div class="level"></div>
                <div class="r-filter__group">
                  <h5 class="r-filter__group__title">Status</h5>
                  <div class="level">
                    <div class="level-left">
                      <b-checkbox v-model="checkbox">Pending</b-checkbox>
                      <b-checkbox v-model="checkbox">Shipped</b-checkbox>
                    </div>
                  </div>
                  <div class="level">
                    <div class="level-left">
                      <b-checkbox v-model="checkbox">Canceled</b-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-half">
                <div class="r-filter__group">
                  <h5 class="r-filter__group__title">Range Date</h5>
                  <b-datepicker
                    icon-right="calendar"
                    v-model="dateFirst"
                  ></b-datepicker>
                  <b-datepicker
                    icon-right="calendar"
                    v-model="dateFirst"
                  ></b-datepicker>
                  <div class="r-date-range">
                    <!-- <r-datepicker v-model="dateFirst"></r-datepicker>
                    <r-datepicker v-model="dateFirst"></r-datepicker> -->
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
              <div class="level">
                <div class="level-item level-right">
                  <b-button class="is-primary is-compound">
                    <span class="button-title">Secondary Text</span>
                    <span class="button-subtitle">This is secondary text</span>
                  </b-button>
                </div>
              </div>
            </template>
          </r-filter>
        </div>
      </div>
    </template>
    <template #page-content>
      <div>
        <div class="r-card">
          <h3>Overview</h3>
          <p>
            Button (Tombol) memberi orang cara untuk memicu sebuah tindakan.
            Mereka biasanya ditemukan di formulir, panel dialog, dan dialog.
            Beberapa tombol dikhususkan untuk tugas tertentu, seperti navigasi,
            tindakan berulang, atau penyajian menu.
          </p>
        </div>
        <r-card>
          <div class="columns is-multiline">
            <div class="column is-full">
              <h4>Default</h4>
              <b-button class="is-primary" outlined>Standard</b-button>
              <b-button class="is-primary">Primary</b-button>
              <b-button disabled>Disabled</b-button>
            </div>
            <div class="column is-full">
              <h4>Compound Buttons</h4>
              <b-button class="is-primary is-compound" outlined>
                <span class="button-title">Standard</span>
                <span class="button-subtitle">This is subtitle</span>
              </b-button>
              <b-button class="is-primary is-compound">
                <span class="button-title">Standard</span>
                <span class="button-subtitle">This is subtitle</span>
              </b-button>
              <b-button class="is-compound" disabled>
                <span class="button-title">Standard</span>
                <span class="button-subtitle">This is subtitle</span>
              </b-button>
            </div>
            <div class="column is-full">
              <h4>Command Bar</h4>
              <b-button class="is-command" iconLeft="filter">Filter</b-button>
            </div>
          </div>
        </r-card>
      </div>
    </template>
  </r-page-modal>
</template>
<script>
export default {
  data() {
    return {
      dateFirst: new Date('11-05-1992'),
      checkbox: false,
    }
  },
  methods: {
    generate() {
      console.log('123123123')
    },
  },
}
</script>
